<div class="d-xl-flex align-items-left" style="width: 100%!important;">
    <div class="flex-grow-1"><!-- my-2 my-xl-0-->
        <div class="input-group">
            <span class="input-group-text bg-transparent search-button" (click)="onRefreshPage()">
                <i class="bx bx-search"></i>
            </span>
            <input type="text" class="form-control search-bar-box" id="page-button-search-box-1"
                   placeholder="{{searchBoxPlaceholder}}" [(ngModel)]="searchText"
                   (keyup)="onKeyUp($event)">
            <button type="button" class="bg-transparent clear-button" (click)="resetSearch()">
                <i class="bx bx-x"></i>
            </button>
        </div>
    </div>
    <div class="d-flex align-items-center" *ngIf="!hideButtonSet1">
        <div class="">
            <button type="button" class="btn btn-white ms-2" (click)="onRefreshPage()"><i
                    class='bx bx-refresh me-0'></i>
            </button>
        </div>
        <div class="" *ngIf="actualDataSet?.length>0">
            <button type="button" class="btn btn-white ms-2" (click)="onDownloadPage()"><i
                    class='bx bx-cloud-download me-0'></i>
            </button>
        </div>
    </div>
    <div class="d-flex align-items-center" *ngIf="!hideButtonSet2">
        <div class="">
            <ng-container *ngFor="let btn of buttonsArray; let i = index;">
                <button
                        type="button"
                        [ngClass]="buttonClass(i)"
                        [id]="btn.buttonNameID"
                        (click)="executeBtnFunction(i)"><i [ngClass]="buttonImgClass(i)"></i> {{btn.buttonLabel}}
                </button>
            </ng-container>
        </div>
    </div>
    <div class="margin-auto d-flex align-items-center">
        <div style="width:100px;margin:0px 5px;">
            <select id="selectedRecsPerPage" class="form-select" [(ngModel)]="selectedRecsPerPage"
                    (change)="onRefreshPage()">
                <option>1</option>
                <option>5</option>
                <option>10</option>
                <option>15</option>
                <option>30</option>
                <option>50</option>
                <option>100</option>
                <option>500</option>
                <option>1000</option>
                <option>5000</option>
                <option>10000</option>
            </select>
        </div>
        <button class="btn btn-sm btn-light" *ngIf="!hideButtonSet1">{{currentPageNavText}}</button>
        <button class="btn btn-white px-2 ms-2" (click)="onNextPrevPage('previous')"><i
                class='bx bx-chevron-left me-0'></i>
        </button>
        <button class="btn btn-white px-2 ms-2" (click)="onNextPrevPage('next')"><i
                class='bx bx-chevron-right me-0'></i>
        </button>
    </div>
</div>