import { RouteInfo } from './horizontal.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

    {
        path: '', title: 'Dashboard', class: '', icon: 'bx bx-home-circle',submenu:[]
    },
    {
        path: '', title: 'Products', class: 'has-arrow', icon: 'bx bx-category',
        submenu: [
            { path: '/products/personal-loans', title: 'Loans', class: '', icon: 'bx bx-right-arrow-alt', submenu: [] },
            { path: '/products/consumer-finance', title: 'Consumer Finance', class: '', icon: 'bx bx-right-arrow-alt', submenu: [] },            
            // { path: '/products/sme-loans', title: 'SME Loans', class: '', icon: 'bx bx-right-arrow-alt', submenu: [] },
            { path: '/products/line-of-credit', title: 'Line of Credit', class: '', icon: 'bx bx-right-arrow-alt', submenu: [] },
        ]
    },    
    {
        path: '', title: 'Account', class: 'has-arrow', icon: 'bx bx-heart',
        submenu: [
            { path: '/account/user-profile', title: 'My Profile', class: '', icon: 'bx bx-right-arrow-alt', submenu: [] },
            { path: '/account/wallet', title: 'Wallet', class: '', icon: 'bx bx-right-arrow-alt', submenu: [] },
            // { path: '/application/file-manager', title: 'Loans', class: '', icon: 'bx bx-right-arrow-alt', submenu: [] },
        ]
    },    
    {
        path: '', title: 'Settings', class: 'has-arrow', icon: 'bx bx-lock',
        submenu: [            
            { path: '/auth/forgot-password', title: 'Forgot Password', class: '', icon: 'bx bx-right-arrow-alt', submenu: [] },
            { path: '/auth/reset-password', title: 'Reset Password', class: '', icon: 'bx bx-right-arrow-alt', submenu: [] },
            { path: '/auth/lock-screen', title: 'Lock Screen', class: '', icon: 'bx bx-right-arrow-alt', submenu: [] },
            { path: '/auth/sign-out', title: 'Log Out', class: '', icon: 'bx bx-right-arrow-alt', submenu: [] },
            
        ]
    },
    {
        path: '', title: 'Reports', class: 'has-arrow', icon: 'bx bx-line-chart',
        submenu: [
            { path: '/charts/apex-chart', title: 'Apex Charts', class: '', icon: 'bx bx-right-arrow-alt', submenu: [] },
            { path: '/charts/chartjs', title: 'ChartJs', class: '', icon: 'bx bx-right-arrow-alt', submenu: [] },
            { path: '/charts/highcharts', title: 'Highcharts', class: '', icon: 'bx bx-right-arrow-alt', submenu: [] },
        ]
    },
];