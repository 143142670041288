<div class="wrapper">
	<app-navbar></app-navbar>
		<app-horizontal-menu></app-horizontal-menu>
			<div class="page-wrapper">
				<div class="page-content-wrapper">
					<div class="page-content">
						<router-outlet></router-outlet>
						<div class="overlay" (click)="toggleSidebar()"></div>
					</div>
				</div>
			</div>
	<app-footer></app-footer>
</div>