import {Component, Input} from '@angular/core';
import {Page} from "../data/model/page";
// import * as XLSX from 'xlsx';
import {tableRowButtonsArrayType} from "../services/global.service"

@Component({
    selector: 'app-page-buttons-bar',
    templateUrl: './page-buttons-bar.component.html',
    styleUrls: ['./page-buttons-bar.component.scss']
})
export class PageButtonsBarComponent {

    @Input()
    buttonsArray: tableRowButtonsArrayType[];

    @Input()
    pageReloadFunction: (searchWord: string, curPage: Page, callBackFn: (newPageValues: Page, pageActualData?: object[]) => void) => void;

    @Input()
    searchBoxPlaceholder: string = "Search";

    @Input()
    page: Page = {
        size: 15,
        pageNumber: 1,
        totalPages: 0,
        totalElements: 0,
        isMultiSelectEnabled: false
    };
    @Input()
    hideButtonSet1: boolean = false;
    @Input()
    hideButtonSet2: boolean = false;

    actualDataSet: object[];
    selectedRecsPerPage: number = this.page.size;
    searchText: string = "";
    currentPageNavText: string;

    constructor() {
    }

    buttonClass(i) {
        return this.buttonsArray[i].buttonMainClass;
    }

    buttonImgClass(i) {
        return this.buttonsArray[i].buttonImgClass;
    }

    executeBtnFunction(i) {
        this.buttonsArray[i].buttonFunction();
    }

    onKeyUp(event) {
        if (event.isComposing || event.keyCode === 229) {
            return;
        }
        if (event.key === 'Enter' || event.keyCode === 13) {
            this.refreshPage();
        }
    }

    onRefreshPage() {
        this.refreshPage();
    }

    // onDownloadPage() {
    //     (async () => {
    //         // console.log(this.actualDataSet);
    //         const inDataToUse = this.actualDataSet.map((val, idx) => {
    //             const nwVal = {
    //                 rowNumber: idx + 1,
    //                 ...val
    //             };
    //             return nwVal;
    //         });
    //         const rowRecords = [];
    //         const rowHeaders = [[]];
    //         Object.keys(inDataToUse[0])
    //             .filter(
    //                 itm1 =>
    //                     itm1.indexOf('_id') === -1
    //                     && itm1.indexOf('slctd_') === -1
    //                     && itm1.indexOf('_val') === -1
    //                     && itm1.indexOf('_proto') === -1
    //                     && itm1.indexOf('proto_') === -1
    //                     && itm1.indexOf('active_flag') === -1
    //                     && itm1.indexOf('depth') === -1
    //                     && itm1.indexOf('path') === -1
    //                     && itm1.indexOf('cycle') === -1
    //                     && itm1.indexOf('space') === -1
    //                     && itm1.indexOf('created_at') === -1
    //                     && itm1.indexOf('created_by') === -1
    //                     && itm1.indexOf('deleted_at') === -1
    //                     && itm1.indexOf('updated_by') === -1
    //                     && itm1.indexOf('updated_at') === -1
    //             )
    //             .map(key => {
    //                 inDataToUse.map((inData, inIdx) => {
    //                     const attemptedParseInt = parseInt(`${inData[key]}`.replace(/,/g, '')
    //                         .replace(/'/g, ''), 10);
    //                     const localVal = inData[key] || attemptedParseInt === 0 ? `${inData[key]}`.replace(/,/g, '')
    //                         .replace(/'/g, '') : inData[key];
    //                     if (localVal && localVal !== '' && (Number.isNaN(Number(localVal)) === false
    //                             || key.toLowerCase()
    //                                 .indexOf('amount') !== -1
    //                             || key.toLowerCase()
    //                                 .indexOf('exchange') !== -1)
    //                         && (`${localVal}`.charAt(0) !== '0' || attemptedParseInt === 0)
    //                         && `${localVal}`.charAt(0) !== '+'
    //                         && key.toLowerCase()
    //                             .indexOf('membership number') === -1) {
    //                         inDataToUse[inIdx][key] = Number(localVal);
    //                     }
    //                     if (rowRecords[inIdx] !== undefined) {
    //                         rowRecords[inIdx][key] = inDataToUse[inIdx][key];
    //                     } else {
    //                         rowRecords.push({rowNumber: inDataToUse[inIdx]["rowNumber"]});
    //                     }
    //                     return inIdx;
    //                 });
    //                 rowHeaders[0].push((key as any).toCamelCase());
    //                 return key;
    //             });
    //         /* generate worksheet and workbook */
    //         const worksheet = XLSX.utils.json_to_sheet(rowRecords);
    //         const workbook = XLSX.utils.book_new();
    //         XLSX.utils.book_append_sheet(workbook, worksheet, "Exported Data");
    //         XLSX.utils.sheet_add_aoa(worksheet, rowHeaders, {origin: "A1"});
    //         XLSX.writeFile(workbook, "exported_data_set.xlsx", {compression: true});
    //     })();
    // }

    onNextPrevPage(btnType: string) {
        let nwPageNumber = this.page.pageNumber;
        if (btnType === 'previous') {
            if (nwPageNumber <= 1) {
                nwPageNumber = this.page.totalPages;
            } else {
                nwPageNumber -= 1;
            }
        } else if (btnType === 'next') {
            if (nwPageNumber >= this.page.totalPages) {
                nwPageNumber = 1;
            } else {
                nwPageNumber += 1;
            }
        }
        this.page.pageNumber = nwPageNumber;
        this.page.size = this.selectedRecsPerPage;
        this.refreshPage();
    }

    refreshPage() {
        this.page.size = this.selectedRecsPerPage;
        const lastPageNum = Math.ceil(this.page.totalElements / this.page.size);
        if (this.selectedRecsPerPage > this.page.totalElements
            || this.page.pageNumber > lastPageNum) {
            this.page.pageNumber = 1;
        }
        if (this.pageReloadFunction) {
            this.pageReloadFunction(this.searchText, this.page, (newPageValues: Page, pageActualData?: object[]) => {
                this.page.totalPages = newPageValues.totalPages;
                this.page.pageNumber = newPageValues.pageNumber;
                this.page.size = newPageValues.size;
                this.page.totalElements = newPageValues.totalElements;
                this.actualDataSet = pageActualData;
                this.reloadNavigationText();
            });
        }
    }

    reloadNavigationText() {
        let recStart = ((this.page.pageNumber - 1) * this.page.size) + 1;

        let recEnd = ((this.page.pageNumber) * this.page.size);
        if (recEnd > this.page.totalElements) {
            recEnd = this.page.totalElements;
        }
        if (recStart > recEnd) {
            this.page.pageNumber = 1;
            recStart = ((this.page.pageNumber - 1) * this.page.size) + 1;
            recEnd = ((this.page.pageNumber) * this.page.size);
            if (recEnd > this.page.totalElements) {
                recEnd = this.page.totalElements;
            }
        }
        if (recStart != recEnd) {
            this.currentPageNavText = `${recStart} - ${recEnd} of ${this.page.totalElements}`;
        } else {
            this.currentPageNavText = `${recStart} of ${this.page.totalElements}`;
        }
    }

    resetSearch() {
        this.searchText = "";
        $("#page-button-search-box-1").trigger("click");
        $("#page-button-search-box-1").trigger("focus");
        this.refreshPage();
    }

    onDownloadPage(){
        
    }

    ngOnInit() {
        this.selectedRecsPerPage = this.page.size;
        this.refreshPage();
    }
}
