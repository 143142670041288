import {Component, Input} from '@angular/core';
import * as $ from 'jquery';

@Component({
    selector: 'app-dialog-popup',
    templateUrl: './dialog-popup.component.html',
    styleUrls: ['./dialog-popup.component.scss']
})
export class DialogPopupComponent {
    @Input()
    dialogType: string = "normal";//formWithButtons

    @Input()
    dialogTitle: string;

    @Input()
    closeDiagFunc = () => {
        return;
    };

    @Input()
    buttonsArray: {
        buttonNameID: string;
        buttonLabel: string;
        buttonMainClass: string;
        buttonFunction: () => void;
    }[];

    dialogNormal: boolean = false;

    constructor() {
    }

    buttonClass(i) {
        return this.buttonsArray[i].buttonMainClass;
    }

    executeBtnFunction(i) {
        this.buttonsArray[i].buttonFunction();
    }

    ngOnInit() {
        this.dialogNormal = this.dialogType === "normal";
        let modalContent: any = $('.modal-content');
        let modalDialog: any = $('.modal-dialog');

        // modalContent.resizable({
        //     minHeight: 300,
        //     minWidth: 300
        // });
        // modalDialog.draggable({
        //     "handle": ".modal-header"
        // });
    }
}
