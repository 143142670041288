import { ClientDetails } from "./client-details";

export class CurrentUser {
    id: number;
    name: string;
    email: string;
    roles: string[];
    permissions: string[];
    profile:ClientDetails;
    
    constructor(
      id: number,
      name: string,
      email: string,
      roles: string[],
      permissions: string[]
    ) {
      this.id = id;
      this.name = name;
      this.email = email;
      this.roles = roles;
      this.permissions = permissions;
    }
  }