<ng-container *ngIf="dialogNormal">
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel">{{dialogTitle}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                (click)="closeDiagFunc()"></button>
    </div>
    <div class="modal-body">
        <ng-content select="p"></ng-content>
    </div>
    <div class="modal-footer">
        <ng-container *ngFor="let btn of buttonsArray; let i = index;">
            <button
                    type="button"
                    [ngClass]="buttonClass(i)"
                    [id]="btn.buttonNameID"
                    [innerText]="btn.buttonLabel"
                    (click)="executeBtnFunction(i)">
            </button>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="!dialogNormal">
    <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel">{{dialogTitle}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                (click)="closeDiagFunc()"></button>
    </div>
    <ng-content></ng-content>
</ng-container>