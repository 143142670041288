// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl : 'http://localhost:8000/api/v1/',
  auth:{
      login:'login',
      signup:'register',
      refreshToken:'refreshToken',
      users:'users',
      
  },
  ussdGroup:{
      groups : 'usergroup'
  },
  client:{
      client:"client",
      relation:"relation",
      employer:"employer",
      employment:"client_employment",
      docs:"client_doc",
      profile:'user_profile'
  },
  loan:"loans",
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
