import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from "./shared/shared.module";
// import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./application/others/layouts/content/content-layout.component";
import { FullLayoutComponent } from "./application/others/layouts/full/full-layout.component";

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthInterceptor } from './shared/utils/auth-interceptor';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { ShareService } from './shared/services/share-service';

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    NgbModule,
    AppRoutingModule,
    SharedModule,
    CommonModule,
    // AgmCoreModule.forRoot({apiKey: 'AIzaSyDKXKdHQdtqgPVl2HI2RnUa_1bjCxRCQo4'}),
    // PerfectScrollbarModule,
    RouterModule,
    JwtModule.forRoot({
      config:{
        tokenGetter:() => localStorage.getItem("token"),
        allowedDomains : ["localhost:4200"]
      }
    })
  ],
  providers: 
    [
      JwtHelperService,{provide: HTTP_INTERCEPTORS, useClass:AuthInterceptor,multi: true},
    // { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
    ShareService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
