import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
  } from "@angular/common/http";
  import { Injectable } from "@angular/core";
  import { Observable,throwError } from "rxjs";
  import { catchError } from 'rxjs/operators';
import { AuthService } from "../services/auth-service";
  
  @Injectable()
  export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService : AuthService) {
      // console.log("route intercepted");
    } 
  
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
      const authToken = this.authService.getAuthToken();
      // console.log("***** auth token "+authToken);
      if(authToken){
          // console.log("***** intercepting here")
          const authReq = req.clone({
              headers: req.headers.append("Authorization",`Bearer ${authToken}`)
          });
          // console.table(authReq);
          return next.handle(authReq).pipe(
            // retry(1),
            catchError((error : HttpErrorResponse) => {
              let errorMessage = "";
              if(error.error instanceof ErrorEvent){
                errorMessage = `Error: ${error.error.message}`;
              }
              else{
                errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                // this.authService.logout();
              }

  
              // window.alert(errorMessage); 
  
              // this.authService.logout();
              // return throwError(()=> new Error(errorMessage));
              return next.handle(req);
  
            })
          );
      }
      else{
        // alert("hey");
      }
      return next.handle(req);
    }
  }