import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { tap, catchError, Observable, of, throwError, map } from "rxjs";
import { CurrentUser } from "../data/model/current-user";
import { ShareService } from "./share-service";
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
  })
export class AuthService {
    private apiUrl : string;
    private jwtHelper = new JwtHelperService();
  
    cfg: any;
    constructor(private http : HttpClient, private shareService: ShareService) { 
      this.cfg = this.shareService.getConfig();
      this.apiUrl = this.cfg.apiBaseUrl;
    }
  
    signup(credentials){  
      console.table(credentials);
      var url = this.apiUrl+this.cfg.auth.signup;
      console.log("URL "+url);
      return this.http.post(url,credentials)
      .pipe(
        tap((response:any)=>{
          console.table(response);
          // this.shareService.setToken(response.token);
          // var user = response.data.user;
          // // var roles:[] = response.data.roles;
          // // var permissions:[] = response.data.permissions;
          // var  currentUser = new CurrentUser(user["id"],user["name"],user["email"],null,null);
          // // localStorage.setItem("token",response.token);
          // this.shareService.setUser(currentUser);
          // this.getRandomUser();
          return 1;
        }),catchError(
          (error: HttpErrorResponse): Observable<any> => {
            // we expect 404, it's not a failure for us.
            if (error.status === 404) {
                return of(null); // or any other stream like of('') etc.
            }
  
            // other errors we don't know how to handle and throw them further.
            return throwError(() => error);
        },
        )
      )
    }
  
    login(credentials){  
      console.table(credentials);
      var url = this.apiUrl+this.cfg.auth.login;
      console.log("URL "+url);
      return this.http.post(url,credentials)
      .pipe(
        tap((response:any)=>{
          console.table(response);
          this.shareService.setToken(response.token);
          var user = response.data.user;
          // var roles:[] = response.data.roles;
          // var permissions:[] = response.data.permissions;
          var  currentUser = new CurrentUser(user["id"],user["name"],user["email"],null,null);
          // localStorage.setItem("token",response.token);
          this.shareService.setUser(currentUser);
          // this.getRandomUser();
        }),catchError(
          (error: HttpErrorResponse): Observable<any> => {
            // we expect 404, it's not a failure for us.
            if (error.status === 404) {
                return of(null); // or any other stream like of('') etc.
            }
  
            // other errors we don't know how to handle and throw them further.
            return throwError(() => error);
        },
        )
      )
    }
  
    getRandomUser(){
      // console.log("random user called");
      // return new Promise((resolve,reject)=>{
      //   return this.http.get("https://randomuser.me/api/").pipe(
      //     tap((res:any)=>{
      //       console.log(" we got data from random user")
      //         console.table(res);
      //         localStorage.setItem("user",res.results)
      //         resolve(res);
      //     })
      //   )
      // })
      this.http.get("https://randomuser.me/api/").pipe(
    map((res: any) => {
      // console.log("We got data from random user");
      // console.table(res);
      this.shareService.setUser(res.results[0])
      // localStorage.setItem("user", );
      // return res;
    })
  ).subscribe(
    res => {
      console.log("Random user data saved to local storage");
    },
    err => {
      console.error("Error fetching random user data", err);
    }
  );
    }
  
    logout(){
      this.shareService.setToken(null);
      this.shareService.setUser(null);
      // localStorage.removeItem("token");
    }
  
    isLoggedIn(){
      const token = this.shareService.getToken();//localStorage.getItem("token");
      try{
        // console.log("jwt "+token);
        return !this.jwtHelper.isTokenExpired(token);
      }
      catch(e){
        // console.log("error "+e);
        return false;
      }
      // return !this.jwtHelper.isTokenExpired(token);
    }
  
    getAuthToken(){
      return this.shareService.getToken();//localStorage.getItem("token");
    }
  
    //add Portal user
    addPortalUser(user:Object){
      // console.log("user "+JSON.stringify(user));
      var data = {
        "email":user["email"],
        "phone_number":user["phone"],
        "password":user["password"],
        "name":user["name"]
      }
      var url = this.apiUrl+this.cfg.auth.users;
      return new Promise(resolve => {
        this.http.post(url,data).subscribe(data => {
          resolve(data);
        })
      })
    }
  
    //retrieve portal users
    retrievePortalUsers(){
      return new Promise(resolve => {
        var url = this.apiUrl+this.cfg.auth.users;
        // console.log("portal users url "+url);
        this.http.get<any[]>(url).subscribe(data => {
          // console.table(data);
          resolve(data);
        });
      })
    }
}
