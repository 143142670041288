import { environment } from "src/environments/environment";
import { CurrentUser } from "../data/model/current-user";
import { ClientDetails } from "../data/model/client-details";

export class ShareService {
    cfg:any;
    token: string;
    user: CurrentUser;
    linked_client_id: number;
  
    constructor() { 
      this.cfg = environment;
    }
  
  
    getConfig(){
      return this.cfg;
    }
  
    setToken(token: string){
      localStorage.setItem("token",token);
      // this.token = token;
    }
  
    getToken(){
      return localStorage.getItem("token");
      // return this.token;
    }
  
    setUser(user: CurrentUser){
      // console.log("current user "+JSON.stringify(user));
      localStorage.setItem("user",JSON.stringify(user));
      this.user = user;
    }
  
    getUser(){
      return JSON.parse(localStorage.getItem("user"));
      // return this.user;
    }
  
    setUserProfile(profile: ClientDetails){
      this.linked_client_id = profile.id;
  
      this.user = this.getUser();
      
      this.user.profile = profile;
  
      this.setUser(this.user);
    }
  
    getLinkedClientId(){
      return this.linked_client_id;
    }
  
    getUserProfile(){
      return this.getUser()["profile"];
    }
  
    getUserId(){
      return this.getUser()["id"];
    }
  
    getUserRoles(){
      return (this.getUser() !== null) ? this.getUser()["roles"] : [];
    }
    getUserPermissions(){
  
      return (this.getUser() !== null) ? this.getUser()["permissions"] : [];
    }
  
    hasAnyRole(roles: string[]) {
      return this.user.roles.some((role)=> roles.includes(role) )
      // return this.user.roles.includes(role);
    }
  
    hasAnyPermission(permissions: string[]) {
      return this.user.permissions.some((permission) => permissions.includes(permission));
      // return this.user.permissions.includes(permission);
    }
}
